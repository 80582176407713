"use client";

import { useEffect, useId, useRef, useState, useCallback } from "react";
import Link from "next/link";
import { Locale } from "../../i18nConfig";
import classNamesBind from "classnames/bind";
import classNames from "classnames";
import styles from "./NavigationMenu.module.css";
import { usePathname } from "next/navigation";
import { LanguageLink } from "../LanguageLink/LanguageLink";
import { useMediaQuery } from "usehooks-ts";

const cx = classNamesBind.bind(styles);

type NavigationMenuProps = {
  languages: { isoCode: Locale; title: string }[];
  navigationItems: { title: string; route: string }[];
  locale: Locale;
  navLabel: string;
  mainMenuLabel: string;
  languageMenuLabel: string;
  className?: string;
  homepageLabel?: string;
};

export function NavigationMenu({
  languages,
  locale,
  navigationItems,
  navLabel,
  mainMenuLabel,
  languageMenuLabel,
  className,
  homepageLabel,
}: NavigationMenuProps) {
  const isAboveMobile = useMediaQuery("(min-width: 576px)");
  const isPrefersReducedMotion = useMediaQuery("(prefers-reduced-motion)");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isMenuClosingAnimation, setIsMenuClosingAnimation] = useState<boolean>(false);
  const pathname = usePathname();
  const menuId = useId();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const linkRefs = useRef<HTMLAnchorElement[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const closeMenu = useCallback(() => {
    // Only play the closing animation, if nav is open
    if (isMenuOpen && !isAboveMobile && !isPrefersReducedMotion) {
      setIsMenuClosingAnimation(true);
    }
    setIsMenuOpen(false);
  }, [isMenuOpen, isAboveMobile, isPrefersReducedMotion]);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const handleButtonClick = () => {
    // Update the state when the button is clicked
    if (isMenuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const onMenuKeyDown: React.KeyboardEventHandler = (keyboardEvent) => {
    const linkElements = linkRefs.current;
    const currentActiveIndex =
      document.activeElement &&
      linkElements.findIndex((linkElement) => linkElement === document.activeElement);

    switch (keyboardEvent.key) {
      case "ArrowUp":
      case "ArrowLeft": {
        /**
         * When the user presses the "Up" or "Left" keys, the previous element
         * in tab flow should be focused.
         *
         * "Circular" focus is optional: we have opted *in* to this behaviour.
         */
        if (currentActiveIndex !== null && currentActiveIndex !== -1) {
          keyboardEvent.preventDefault(); // Block scrolling
          const previousElement = linkElements.at((currentActiveIndex - 1) % linkElements.length);

          previousElement?.focus();
        }
        break;
      }
      case "ArrowDown":
      case "ArrowRight": {
        /**
         * When the user presses the "Down" or "Right" keys, the next element in
         * tab flow should be focused.
         *
         * "Circular" focus is optional: we have opted *in* to this behaviour.
         */
        if (currentActiveIndex !== null && currentActiveIndex !== -1) {
          keyboardEvent.preventDefault(); // Block scrolling
          const nextElement = linkElements.at((currentActiveIndex + 1) % linkElements.length);

          nextElement?.focus();
        }
        break;
      }

      case "Escape": {
        /**
         * When the user presses the "Esc" key, the menu should be closed.
         */
        // setIsMenuOpen(false);
        closeMenu();
        buttonRef.current?.focus();
        break;
      }
      case "Home": {
        /**
         * When the user presses the "Home" key, the first link in the list
         * should be focused.
         */
        keyboardEvent.preventDefault(); // Block scrolling
        linkElements.at(0)?.focus();
        break;
      }
      case "End": {
        /**
         * When the user presses the "End" key, the last link in the list should
         * be focused.
         */
        keyboardEvent.preventDefault(); // Block scrolling
        linkElements.at(-1)?.focus();
        break;
      }
    }
  };

  /**
   * When menu loses focus, it should automatically close.
   */
  const onBlur: React.FocusEventHandler = (focusEvent) => {
    /**
     * blur events bubble, so this event might have just been triggered from one
     * link element losing focus to the next/previous link element. So in order
     * to check whether or not th
     */
    if (!containerRef.current?.contains(focusEvent.relatedTarget)) {
      closeMenu();
    }
  };

  // const onContainerKeyDown: React.KeyboardEventHandler = (keyboardEvent) => {
  //   if ([" " /* Spacebar */, "Enter"].includes(keyboardEvent.key)) {
  //     openMenu();
  //     /**
  //      * At this very moment, the linkRefs are display:none (until the component
  //      * finishes re-rendering) so we need to wrap `.focus` in a small timeout
  //      * to circumvent that race-condition.
  //      */
  //     window.setTimeout(() => {
  //       linkRefs.current[0]?.focus();
  //     }, 100);
  //   }
  // };

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as HTMLElement)) {
        closeMenu();
      }
    };
    window.addEventListener("click", onClick);

    return () => window.removeEventListener("click", onClick);
  }, [closeMenu]);

  /* TODO: A better solution would be to make everthing apart from the nav `inert` */
  useEffect(() => {
    const body = document.body;

    if (isMenuOpen && !isAboveMobile) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "";
    }

    // Cleanup function
    return () => {
      body.style.overflow = "";
    };
  }, [isMenuOpen, isAboveMobile]);

  /**
   * Use this `onAnimationEnd={handleAnimationEnd} `in combination with `data-closing={isMenuClosingAnimation}` on any UI that animates when closing.
   * Often we need to set a menu to `display: none;` but only after an animation has ended,
   * Elsewhere we set a "closing-animation" class or data-attribute, this triggers a CSS closing animation and keeps the element as "disaply: block;",
   * once that animation has ended we want to remove that class/attribute and CSS reverts back to "display: none;".
   * */
  const handleAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    if (event.animationName === styles["fade-out"]) {
      setIsMenuClosingAnimation(false);
    }
  };

  return (
    <div className={classNames(className, cx("container"))} data-open={isMenuOpen}>
      <nav
        className={cx("nav")}
        ref={containerRef}
        onBlur={onBlur}
        role="navigation"
        aria-label={navLabel}
      >
        <button
          className={cx("button")}
          aria-controls={menuId}
          aria-expanded={isMenuOpen}
          ref={buttonRef}
          // Deliberately not using `onClick` because that would clash with `onContainerKeyDown` for keyboard users
          // onMouseDown={() => setIsMenuOpen((current) => !current)}
          onClick={handleButtonClick}
        >
          Menu
        </button>

        <div
          className={cx("clip-wrapper")}
          ref={wrapperRef}
          onAnimationEnd={handleAnimationEnd}
          data-closing={isMenuClosingAnimation}
          data-open={isMenuOpen}
        >
          <div
            className={cx("menu")}
            id={menuId}
            role="group"
            aria-label={mainMenuLabel}
            hidden={!isMenuOpen}
            suppressHydrationWarning={true}
            // @ts-ignore: known issue https://github.com/facebook/react/issues/17157
            inert={!isMenuOpen ? "" : undefined}
          >
            <ul className={cx("items")} onKeyDown={onMenuKeyDown}>
              <li className={cx("item")}>
                <Link className={cx("link")} href={`/${locale}`} onClick={() => closeMenu()}>
                  {homepageLabel ? homepageLabel : "Home"}
                </Link>
              </li>
              {navigationItems.map((item, index) => (
                <li key={index} className={cx("item")} data-active={pathname === item.route}>
                  <Link
                    className={cx("link")}
                    href={item.route}
                    onClick={() => closeMenu()}
                    aria-current={pathname === item.route ? "page" : undefined}
                    ref={(element) => {
                      if (element) {
                        linkRefs.current[index] = element;
                      }
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <div
              className={cx("menu-language-switch")}
              role="group"
              aria-label={languageMenuLabel}
              hidden={!isMenuOpen && !isAboveMobile}
              suppressHydrationWarning={true}
              // @ts-ignore: known issue https://github.com/facebook/react/issues/17157
              inert={!isMenuOpen && !isAboveMobile ? "" : undefined}
            >
              {languages.map((language, index) => (
                <LanguageLink
                  key={index}
                  isoCode={language.isoCode}
                  title={language.title}
                  className={cx("language-button", {
                    "language-button--active": locale === language.isoCode,
                  })}
                />
              ))}
            </div>
          </div>
          <div
            className={cx("language-switch")}
            role="group"
            aria-label={languageMenuLabel}
            hidden={!isMenuOpen && !isAboveMobile}
            suppressHydrationWarning={true}
            // @ts-ignore: known issue https://github.com/facebook/react/issues/17157
            inert={!isMenuOpen && !isAboveMobile ? "" : undefined}
          >
            {languages.map((language, index) => (
              <LanguageLink
                key={index}
                isoCode={language.isoCode}
                title={language.title}
                className={cx("language-button", {
                  "language-button--active": locale === language.isoCode,
                })}
              />
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
}
