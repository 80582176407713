"use client";

/**
 * Component which links to the current page, but replaces the language.
 */

import Link from "next/link";
import { usePathname } from "next/navigation";
import { Locale } from "../../i18nConfig";

export const LanguageLink = ({
  isoCode,
  title,
  className,
}: {
  isoCode: Locale;
  title: string;
  className?: string;
}) => {
  const pathname = usePathname();

  // Replace the first segment in the pathnane
  const href = pathname.split("/").toSpliced(1, 1, isoCode).join("/");

  return (
    <Link hrefLang={isoCode} href={href} className={className} aria-label={title}>
      {isoCode}
    </Link>
  );
};
